/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import { rhythm } from '../utils/typography';

const Bio: React.FC = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      resume: file(absolutePath: {regex: "/resume.pdf/" }) {
          publicURL
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `);

  const Container = styled.div`
    display: flex;
    margin-bottom: ${rhythm(2.5)};
  `;

  const Avatar = styled(Image)`
    margin-right: ${rhythm(1 / 2)};
    margin-bottom: 0;
    min-width: 50px;
    border-radius: 100%;
  `;

  const { author, social } = data.site.siteMetadata;
  return (
    <Container>
      <Avatar
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        imgStyle={{
          borderRadius: '50%',
        }}
      />
      <p>
        Written by
        {' '}
        <strong>{author}</strong>
        {' '}
        who lives and works in San
        Francisco building useful things.

        <a href={`https://twitter.com/${social.twitter}`}>
          You should follow him on Twitter
        </a>
      </p>
      <a href={data.resume.publicURL} download>Resume</a>
    </Container>
  );
};

export default Bio;
